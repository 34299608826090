import React from "react";

import Carousel from "./Carousel";

import Maternity1 from "../../assets/img/maternity/maternity1.jpg";
import Maternity2 from "../../assets/img/maternity/maternity2.jpg";
import Maternity3 from "../../assets/img/maternity/maternity3.jpg";
import Maternity4 from "../../assets/img/maternity/maternity4.jpg";
import Maternity5 from "../../assets/img/maternity/maternity5.jpg";
import Maternity6 from "../../assets/img/maternity/maternity6.jpg";
import Maternity7 from "../../assets/img/maternity/maternity7.jpg";
import Maternity8 from "../../assets/img/maternity/maternity8.jpg";
import Maternity9 from "../../assets/img/maternity/maternity9.jpg";
import Maternity10 from "../../assets/img/maternity/maternity10.jpg";
import Maternity11 from "../../assets/img/maternity/maternity11.jpg";
import Maternity12 from "../../assets/img/maternity/maternity12.jpg";
import Maternity13 from "../../assets/img/maternity/maternity13.jpg";
import Maternity14 from "../../assets/img/maternity/maternity14.jpg";

const MaternityPictures = [
  Maternity1,
  Maternity2,
  Maternity3,
  Maternity4,
  Maternity5,
  Maternity6,
  Maternity7,
  Maternity8,
  Maternity9,
  Maternity10,
  Maternity11,
  Maternity12,
  Maternity13,
  Maternity14,
];

const Maternity = () => {
  return (
    <div>
      <Carousel images={MaternityPictures} />
    </div>
  );
};

export default Maternity;
