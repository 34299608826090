import React from "react";

import Carousel from "./Carousel";

import Animal1 from "../../assets/img/animals/animal1.jpg";
import Animal2 from "../../assets/img/animals/animal2.jpg";
import Animal3 from "../../assets/img/animals/animal3.jpg";
import Animal4 from "../../assets/img/animals/animal4.jpg";
import Animal5 from "../../assets/img/animals/animal5.jpg";

const AnimalsPictures = [Animal1, Animal2, Animal3, Animal4, Animal5];

const Portrait = () => {
  return (
    <div>
      <Carousel images={AnimalsPictures} />
    </div>
  );
};

export default Portrait;
