import React from "react";

import Carousel from "./Carousel";

import Corpo1 from "../../assets/img/corporate/corpo1.jpg";
import Corpo2 from "../../assets/img/corporate/corpo2.jpg";
import Corpo3 from "../../assets/img/corporate/corpo3.jpg";
import Corpo4 from "../../assets/img/corporate/corpo4.jpg";
import Corpo5 from "../../assets/img/corporate/corpo5.jpg";
import Corpo6 from "../../assets/img/corporate/corpo6.jpg";
import Corpo7 from "../../assets/img/corporate/corporate7.jpg";

const CorporatePictures = [
  Corpo1,
  Corpo2,
  Corpo3,
  Corpo4,
  Corpo5,
  Corpo6,
  Corpo7,
];

const Portrait = () => {
  return (
    <div>
      <Carousel images={CorporatePictures} />
    </div>
  );
};

export default Portrait;
