import React from "react";

import Carousel from "./Carousel";

import Baby1 from "../../assets/img/baby/baby1.jpg";
import Baby2 from "../../assets/img/baby/baby2.jpg";
import Baby3 from "../../assets/img/baby/baby3.jpg";
import Baby4 from "../../assets/img/baby/baby4.jpg";
import Baby5 from "../../assets/img/baby/baby5.jpg";
import Baby6 from "../../assets/img/baby/baby6.jpg";
import Baby7 from "../../assets/img/baby/baby7.jpg";
import Baby8 from "../../assets/img/baby/baby8.jpg";
import Baby9 from "../../assets/img/baby/baby9.jpg";
import Baby10 from "../../assets/img/baby/baby10.jpg";

const BabyPictures = [
  Baby1,
  Baby2,
  Baby3,
  Baby4,
  Baby5,
  Baby6,
  Baby7,
  Baby8,
  Baby9,
  Baby10,
];

const Baby = () => {
  return (
    <>
      <Carousel images={BabyPictures} />
    </>
  );
};

export default Baby;
